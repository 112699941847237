import React from 'react'
import tailwindConfig from 'tailwind.config'
import CircleIcon from '@/components/icons/CircleIcon'
import VerifyRoundIcon from '@/components/icons/VerifyRoundIcon'
import TaskStatus from '@/enums/taskStatus'
import CloseIcon from '@/components/icons/CloseIcon'

interface IProps {
  status: TaskStatus
  iconSize?: number
}

export const TaskStatusIcon = ({ status, iconSize = 24 }: IProps) => {
  if (status === TaskStatus.COMPLETE) {
    return <VerifyRoundIcon mode="success" size={iconSize} />
  } else if (status === TaskStatus.TERMINATED) {
    return (
      <span className="w-6 h-6">
        <CloseIcon className="w-6 h-6 text-red-900" />
      </span>
    )
  } else if (status === TaskStatus.ASSIGNED) {
    return (
      <CircleIcon
        size={iconSize}
        weight="bold"
        color={tailwindConfig.theme.extend.colors.neutralsAdditional500}
      />
    )
  } else if (status === TaskStatus.IN_PROGRESS) {
    return (
      <CircleIcon
        size={iconSize}
        weight="bold"
        color={tailwindConfig.theme.extend.colors.SecondaryBlue800}
      />
    )
  } else if (status === TaskStatus.NOT_STARTED) {
    return (
      <CircleIcon
        size={iconSize}
        weight="bold"
        color={tailwindConfig.theme.extend.colors.neutralsAdditional500}
      />
    )
  }
  return null
}
