import constants from '@/config/constants'
import DocVerifiedStatus from '@/enums/docVerifiedStatus'
import InvitationStatus from '@/enums/invitationStatus'
import TaskStatus from '@/enums/taskStatus'
import TaskType from '@/enums/taskType'
import { IProposedUserResponse, ITaskType } from '@/services/workflow'
import { UserInfo } from '@/state/authentication'
import VleiWorkflowTask from '@/types/vlei-workflow-task'
import { isEmpty } from 'lodash'
import type {
  TaskActorResponseDto,
  WorkflowExecutedResponseDto,
  WorkflowInstanceDto
} from '../../api/origin-workflow-svc'

const { ROLES } = constants

export const taskTypeToMenuItemMap: Record<
  string,
  {
    taskType: string
    label: string
    href: string
    grants?: string[]
    excludes?: string[]
    isPublic: boolean
  }
> = {
  [VleiWorkflowTask.INTRODUCTION]: {
    taskType: VleiWorkflowTask.INTRODUCTION,
    label: 'le.vlei.workflow.task.introduction',
    href: '/vlei/introduction',
    grants: [ROLES.EMPLOYEE, ROLES.DAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.ORG_DOCUMENTATION]: {
    taskType: VleiWorkflowTask.ORG_DOCUMENTATION,
    label: 'le.vlei.workflow.task.entityInformation',
    href: '/vlei/documentation',
    grants: [ROLES.EMPLOYEE, ROLES.DAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.PROPOSE_COORDINATOR]: {
    taskType: VleiWorkflowTask.PROPOSE_COORDINATOR,
    label: 'le.vlei.workflow.task.coordinator',
    href: '/vlei/coordinator',
    grants: [ROLES.EMPLOYEE, ROLES.DAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.QAR_ORG_RESEARCH]: {
    taskType: VleiWorkflowTask.QAR_ORG_RESEARCH,
    label: 'le.vlei.workflow.task.qarOrgVerification',
    href: '/vlei/org-verification',
    grants: [ROLES.QAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.IDENTITY_VERIFICATION]: {
    taskType: VleiWorkflowTask.IDENTITY_VERIFICATION,
    label: 'vlei.heading.identityVerification',
    href: '/vlei/identityVerification/start',
    grants: [ROLES.LAR, ROLES.DAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.ASSIGN_REPRESENTATIVES]: {
    taskType: VleiWorkflowTask.ASSIGN_REPRESENTATIVES,
    label: 'header.vlei.defineRepresentatives',
    href: '/vlei/representative/add',
    grants: [ROLES.DAR],
    excludes: [],
    isPublic: true
  },
  [VleiWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY]: {
    taskType: VleiWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY,
    label: 'header.vlei.issuanceCeremony',
    href: '/vlei/issuanceCeremony',
    grants: [ROLES.LAR, ROLES.DAR, ROLES.QAR],
    excludes: [],
    isPublic: true
  },

  [VleiWorkflowTask.SUBMIT_DOCUMENTS]: {
    taskType: VleiWorkflowTask.SUBMIT_DOCUMENTS,
    label: 'workflow.name.unknown',
    href: '/',
    grants: [ROLES.LAR, ROLES.DAR],
    excludes: [],
    isPublic: false
  },
  [VleiWorkflowTask.SUPPLY_PREREQUISITES]: {
    taskType: VleiWorkflowTask.SUPPLY_PREREQUISITES,
    label: 'workflow.name.unknown',
    href: '/',
    grants: [ROLES.LAR, ROLES.DAR],
    excludes: [],
    isPublic: false
  },
  [VleiWorkflowTask.PROPOSE_RECIPIENTS]: {
    taskType: VleiWorkflowTask.PROPOSE_RECIPIENTS,
    label: 'oor.workflow.name.proposeRecipients',
    href: '/oor/recipient',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: false
  },
  [VleiWorkflowTask.ISSUANCE_CEREMONY]: {
    taskType: VleiWorkflowTask.ISSUANCE_CEREMONY,
    label: 'vlei.menu.task.issuanceCeremony',
    href: '/vlei/issuance-ceremony',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  }
}

export function findTaskIdByType(
  data: WorkflowExecutedResponseDto,
  taskTypeToFind: TaskType
): string | null {
  const assignedTask = data.assignedTasks.find(
    // todo speak with BE team to update swagger docs
    // @ts-ignore
    (task) => task.task_type === taskTypeToFind
  )
  return assignedTask ? assignedTask.id : null
}

export function getTaskActorByType(
  res: WorkflowInstanceDto,
  taskType: TaskType
): TaskActorResponseDto | null {
  const task = res.taskTypes.find(
    (task: ITaskType) => task.taskType === taskType
  )
  if (task) {
    const taskActor = task.taskActors.find(
      (actor) => actor.taskType === taskType
    )
    return taskActor || null
  }
  return null
}

export const isTaskComplete = (status: TaskStatus | DocVerifiedStatus) => {
  return [TaskStatus.COMPLETE, DocVerifiedStatus.COMPLETE].includes(status)
}

export const getInvitationStatus = (
  proposedUserResponse: IProposedUserResponse
) => {
  const isApproved = !isEmpty(proposedUserResponse.approvedByUser)
  const isAccepted = proposedUserResponse.isAccepted

  if (isApproved && isAccepted) return InvitationStatus.ACCEPTED
  else if (isAccepted && !isApproved) return InvitationStatus.IN_PROGRESS
  else if (isAccepted === false) return InvitationStatus.REJECTED
  else return InvitationStatus.NOT_STARTED
}

export const getInvitationStatusLabel = (status: InvitationStatus) => {
  if (status === InvitationStatus.ACCEPTED) return 'status.vlei.completed'
  else if (status === InvitationStatus.IN_PROGRESS)
    return 'status.vlei.inProgress'
  else if (status === InvitationStatus.REJECTED)
    return 'status.vlei.rejectedRequest'
  else return 'status.vlei.notStarted'
}

export const getPillTypeByStatus = (status: InvitationStatus) => {
  return status === InvitationStatus.IN_PROGRESS
    ? 'inProgress'
    : status === InvitationStatus.REJECTED
    ? 'rejected'
    : status === InvitationStatus.ACCEPTED
    ? 'success'
    : 'pending'
}

export const filterTask = (filteredNodes: any, userInfo: UserInfo) => {
  return filteredNodes.filter((item) => {
    const taskItem = taskTypeToMenuItemMap[item.taskType]

    // Include items where at least one of the userInfo.grants is included in menuItem.grants
    const includesGrant = userInfo?.grants?.some((grant) =>
      taskItem.grants.includes(grant.toUpperCase())
    )

    // Exclude items where at least one of the userInfo.grants is included in menuItem.excludes
    const excludesGrant = userInfo?.grants?.some((grant) =>
      taskItem.excludes?.includes(grant.toUpperCase())
    )

    // Include items that satisfy the conditions
    return includesGrant && !excludesGrant
  })
}
