import React, { ReactNode } from 'react'

type IPillType =
  | 'inProgress'
  | 'rejected'
  | 'pending'
  | 'success'
  | 'info'
  | 'canceled'

type IPillSize = 'small' | 'medium'

export interface IPillProps {
  label: ReactNode
  type?: IPillType
  size?: IPillSize
  className?: string
  icon?: ReactNode
}

const Pill = ({
  label,
  type = 'pending', // Default type is set to 'pending'
  size = 'small',
  className,
  icon,
  ...rest
}: IPillProps) => {
  let bgColor = ''
  let textColor = ''

  // Assign colors based on type
  // if (type === 'inProgress') {
  //   bgColor = 'bg-zinc-100'
  //   textColor = 'text-neutral-800'
  // } else if (type === 'rejected') {
  //   bgColor = 'bg-rose-100'
  //   textColor = 'text-red-800'
  // } else if (type === 'success') {
  //   bgColor = 'bg-lime-100'
  //   textColor = 'text-green-900'
  // } else if (type === 'pending') {
  //   bgColor = 'bg-orange-50'
  //   textColor = 'text-amber-600'
  // } else {
  //   bgColor = 'bg-gray-200'
  //   textColor = 'text-gray-800'
  // }

  // Assign colors based on type
  if (type === 'inProgress') {
    bgColor = 'bg-zinc-100'
    textColor = 'text-neutral-800'
  } else if (type === 'rejected') {
    bgColor = 'bg-rose-100'
    textColor = 'text-red-800'
  } else if (type === 'success') {
    bgColor = 'bg-successGreen50'
    textColor = 'text-TextGreen'
  } else if (type === 'pending') {
    bgColor = 'bg-SecondaryOrange'
    textColor = 'text-SecondaryOrange900'
  } else if (type === 'info') {
    bgColor = 'bg-SecondaryBlue10'
    textColor = 'text-royalBlue'
  } else if (type === 'canceled') {
    bgColor = 'bg-zinc-100'
    textColor = 'text-grey-100'
  } else {
    bgColor = 'bg-gray-200'
    textColor = 'text-gray-800'
  }

  // const pillClasses = `
  //   ${bgColor} ${textColor}
  //   ${size === 'small' ? 'h-28px text-bold14' : 'h-36px text-bold16'}
  //   inline-flex p-2 align-middle rounded-md border text-sm font-medium leading-none
  // `
  const pillClasses = `
  ${bgColor} ${textColor}
  ${size === 'small' ? 'h-[26px] text-bold14' : 'h-36px text-bold16'}
  inline-flex px-2 py-1 align-middle rounded-md border text-sm font-medium leading-none items-center ${className}
`
  return (
    <span className={pillClasses} {...rest}>
      <>{icon}</>
      {label}
    </span>
  )
}

export default Pill
