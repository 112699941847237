enum VleiWorkflowTask {
  INTRODUCTION = 'INTRODUCTION',
  ORG_DOCUMENTATION = 'ORG_DOCUMENTATION',
  PROPOSE_COORDINATOR = 'PROPOSE_COORDINATOR',
  PROPOSE_RECIPIENTS = 'PROPOSE_RECIPIENTS',
  SUBMIT_DOCUMENTS = 'SUBMIT_DOCUMENTS',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  ASSIGN_REPRESENTATIVES = 'ASSIGN_REPRESENTATIVES',
  SUPPLY_PREREQUISITES = 'SUPPLY_PREREQUISITES',
  SCHEDULE_ISSUANCE_CEREMONY = 'SCHEDULE_ISSUANCE_CEREMONY',
  ISSUANCE_CEREMONY = 'ISSUANCE_CEREMONY',
  QAR_ORG_RESEARCH = 'QAR_ORG_RESEARCH'
}

export default VleiWorkflowTask
